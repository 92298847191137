import styled from 'styled-components';

export const RelatedArticlesContainer = styled.div`
	max-width: 1440px;
	margin: 0 auto;
	padding: ${(props) => props.theme.globalPaddingMobile};

	@media (min-width: 768px) {
		padding: 0 30px;
	}

	@media (min-width: 1280px) {
		padding: ${(props) => props.theme.globalPaddingDesktop};
	}

`;
export const Header = styled.h2`
	text-align: center;
	border-top: 1px solid #889390;
	border-bottom: ${(props) => (props.unearth ? '1px solid #889390' : null)};
	font-family: ${(props) => (props.unearth ? 'Maison Neue' : 'Freight Big')};
	padding-top: ${(props) => (props.unearth ? '16px' : '45px')};
	padding-bottom:  ${(props) => (props.unearth ? '16px' : null)};

	text-transform: none;
	font-size: 32px;
	z-index: -1000;
	margin-bottom: 34px;
	margin-top: ${(props) => (props.unearth ? '34px' : null)};


	@media (min-width: 768px) {
		padding-top:  ${(props) => (props.unearth ? '31px' : '67px')};
		padding-bottom:  ${(props) => (props.unearth ? '31px' : null)};

		font-size: 46px;
		margin-bottom: 46px;
	margin-top: ${(props) => (props.unearth ? '46px' : null)};

		border-width: ${(props) => (props.noBorder ? '0' : '1px')};
	}
`;
export const ThreeGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 18px;

	@media (min-width: 480px) {
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 10px;
	}

	@media (min-width: 768px) {
		grid-template-columns: 1fr 1fr 1fr;
	}

	@media (min-width: 1024px) {
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 55px;
	}
`;

export const TwoGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	position: relative;
	margin-bottom: 10px;
	padding-bottom: 20px;

	@media (min-width: 768px) {
		padding-bottom: 0px;
		grid-gap: 30px;
		grid-template-columns: 1fr 1fr;
	}
	@media (min-width: 1280px) {
		grid-gap: 55px;
		margin-bottom: 20px;
	}

	/*
::after {
		content: "";
		position: absolute;
		margin: 0;
		background: black;
		height: 1px;
		opacity: 1;
		width: 100%;
		display: block;

	} */
`;

export const MoreItineraries = styled.div`
	display: flex;
	flex-direction: column;

	h5 {
		a {
			color: #212c1f;
		}
		margin-top: 50px;
		font-weight: 400;
		font-size: 14px;
		letter-spacing: -0.11px;
		text-align: center;
		line-height: 20px;

		:hover {
			a {
				color: #a55331;
			}
		}
	}
`;
