import styled from 'styled-components'

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: ${props => (props.unearth ? '1fr' : '1fr 1fr')};
  grid-column-gap: 15px;
  grid-gap: ${props => props.unearth && '30px'};
  grid-auto-flow: row;
  justify-content: center;

  @media (min-width: 480px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: ${props => props.unearth && '15px'};
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 30px;
    grid-gap: ${props => props.unearth && '30px'};
  }

  @media (min-width: 1280px) {
    grid-column-gap: 55px;
    grid-gap: ${props => props.unearth && '55px'};
  }
`

export const Subheader = styled.h2`
  text-align: center;
  font-family: Freight Big;
  text-transform: none;
  font-size: 32px;
  z-index: -1000;
  margin-bottom: 15px;

  @media (min-width: 768px) {
    font-size: 46px;
    margin-top: 60px;
    margin-bottom: 30px;
  }
`

export const RegionSelectorWrapper = styled.div`
  margin: 0 8px;

  @media (min-width: 768px) {
    max-width: 370px;
    width: 370px;
    margin: 0;
    margin-top: 10px;
  }

  @media (min-width: 1024px) {
    margin-top: 40px;
    margin-bottom: -10px;
  }

  @media (min-width: 1780px) {
    margin-top: -48px;
  }

  > div {
    max-width: 100%;
  }
`
