import React, { Component } from 'react'

import { Teaser } from '../Teaser'
import { TeaserUnearth } from '../TeaserUnearth'

// import { MoreResults } from '../MoreResults';

import SelectComponent from '../Select/selectComponent'
import { GridWrapper, RegionSelectorWrapper } from './style.js'

import {
  ComponentContainer,
  OverflowContainer,
} from '../../utils/globalStyledComponents'

class SubcategoryLanding extends Component {
  constructor(props) {
    super(props)

    this.state = {
      region: { value: null, label: 'All' },
    }
  }

  updateRegion(region) {
    this.setState({ region })
    // this.algoliaIndex.updateRegion(region.value)
    // this.search()
  }

  render() {
    // console.log('props:', this.props)

    const regionOptions = [
      { value: null, label: 'All' },
      ...this.props.regions.map(region => {
        return { value: region.title, label: region.title }
      }),
    ]

    const regionFilteredItems = this?.state?.region?.value
      ? this.props.listingPages.filter(
          item => item?.avContactInfo?.region?.title === this.state.region.value
        )
      : this.props.listingPages

    return (
      <div>
        <ComponentContainer>
          <RegionSelectorWrapper>
            <SelectComponent
              placeholder="Region"
              value={this.state.region}
              options={regionOptions}
              onChange={this.updateRegion.bind(this)}
            />
          </RegionSelectorWrapper>
          <OverflowContainer>
            <GridWrapper unearth={this.props.unearth}>
              {regionFilteredItems?.length
                ? regionFilteredItems.map((teaser, index) =>
                    this.props.unearth ? (
                      <TeaserUnearth
                        key={index}
                        standard
                        title={teaser.title}
                        path={teaser.uri}
                        excerpt={teaser.excerpt}
                        databaseId={teaser.databaseId}
                        date={teaser.dates}
                        category={teaser.categories}
                        img={{
                          ...teaser.avContent?.featureImage,
                          sizes: '364px',
                        }}
                      />
                    ) : (
                      <Teaser
                        key={index}
                        standard
                        subcategory
                        title={teaser.title}
                        openInNewTab
                        url={teaser.uri}
                        databaseId={teaser.databaseId}
                        desc={teaser.excerpt}
                        categories={teaser.categories}
                        img={{
                          ...teaser.avContent?.featureImage,
                          sizes: '364px',
                        }}
                        region={teaser?.avContactInfo?.region}
                      />
                    )
                  )
                : null}
            </GridWrapper>
            {!regionFilteredItems?.length && (
              <p>No results found for this region.</p>
            )}
          </OverflowContainer>
        </ComponentContainer>
      </div>
    )
  }
}

export default SubcategoryLanding
