import styled from 'styled-components';

export const StoriesWidget = styled.div`
	padding: 0 10px;
	padding-top: 20px;
	margin: auto;
	background-color: #fbf9f6;
	@media (min-width: 768px) {
		padding: 0 30px;
		padding-top: 70px;
	}

	@media (min-width: 1280px) {
		max-width: 1200px;
		padding: 0;
		padding-top: 60px;
	}
`;

export const TwoGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	position: relative;
	margin-bottom: 10px;

	padding-bottom: 20px;

	@media (min-width: 768px) {
		padding-bottom: 0px;
		grid-gap: 30px;
		grid-template-columns: 1fr 1fr;
	}
	@media (min-width: 1280px) {
		grid-gap: 55px;
		margin-bottom: 20px;
	}
`;
