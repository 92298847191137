import React, { Component } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../components/layout'
import { RelatedArticles } from '../components/RelatedArticles'
import { SubcategoryLanding } from '../components/SubcategoryLanding'
import SEO from '../components/seo'

// import { ResponsiveImage } from '../components/ResponsiveImage';

class PageTemplate extends Component {
  render() {
    let post
    let regions
    let parent

    if (this.props.preview) {
      post = this.props.data
      parent = { path: '', slug: '' }
    } else {
      post = this.props.data.page
      regions = this.props.data.allWpRegion
      parent = { path: '', slug: '' }
    }

    // const items = post.avListingPage.handpickedContent

    // let types = []
    // items.forEach(item => {
    //   item.operatorTypes.nodes.forEach(type => {
    //     types = [...types, type]
    //   })
    // })
    // console.log('types:', types)

    // const yo = types.filter(
    //   (value, index, self) =>
    //     index ===
    //     self.findIndex(t => t.place === value.place && t.name === value.name)
    // )
    // console.log('yo:', yo)

    return (
      <Layout
        subcategory
        headerText={post.avIntro.introDescription}
        categories={post.categories.nodes}
        generic
        headerImage={post.avContent.featureImage}
        headerTitle={post.title}
        breadcrumb={{ name: parent.title, slug: parent.path }}
      >
        <SEO
          title={post.seo.title}
          image={post?.avContent?.featureImage?.sourceUrl}
          description={post.seo.metaDesc}
        />

        <SubcategoryLanding
          listingPages={post.avListingPage.handpickedContent}
          regions={regions?.nodes}
        />

        <RelatedArticles
          noBorder
          heading={post.avRelatedPages.relatedHeading}
          relatedPages={post.avRelatedPages.relatedPages}
        />
      </Layout>
    )
  }
}

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    allWpRegion {
      nodes {
        title
      }
    }
    page: wpPage(id: { eq: $id }) {
      title
      ...RelatedPages
      seo {
        title
        metaDesc
      }
      avIntro {
        introDescription
      }

      content
      avListingPage {
        handpickedContent {
          ... on WpOperator {
            title
            uri
            excerpt
            databaseId
            operatorTypes {
              nodes {
                name
                slug
              }
            }
            avContent {
              featureImage {
                sourceUrl
                srcSet
              }
            }
            avContactInfo {
              region {
                ... on WpRegion {
                  title
                  slug
                  regionField {
                    regionPage {
                      ... on WpPage {
                        title
                        uri
                      }
                    }
                  }
                }
              }
            }
          }
          ... on WpEvent {
            title
            uri
            excerpt
            databaseId
            operatorTypes {
              nodes {
                name
                slug
              }
            }
            avContent {
              featureImage {
                sourceUrl
                srcSet
              }
            }
            avContactInfo {
              region {
                ... on WpRegion {
                  title
                  slug
                  regionField {
                    regionPage {
                      ... on WpPage {
                        title
                        uri
                      }
                    }
                  }
                }
              }
            }
          }

          ... on WpPage {
            title
            excerpt
            uri

            avContent {
              featureImage {
                sourceUrl
                srcSet
              }
            }
          }
        }
      }
      categories {
        nodes {
          name
          slug
        }
      }
      avIntro {
        introDescription
        introHeading
      }
      avContent {
        featureImage {
          sourceUrl
        }
      }
    }
  }
`
