import styled from 'styled-components';

export const Header = styled.div`
	display: flex;
	width: 100%;

	margin:0 auto;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
	padding-bottom: ${(props) => (props.secondary ? '17px' : props.bold ? '28px' : props.smallBold  ? '20px' : null)};
	position: relative;
	margin-bottom: ${(props) => ( props.secondary ? '20px' :props.bold ? '30px' : null)};


	@media (min-width: 768px) {
	margin-bottom: ${(props) => ( props.secondary ? '30px' : null)};

		padding-bottom: ${(props) => (props.smallBold  ? '40px' : null)};
	}
	@media (min-width: 1280px) {
		padding-bottom: ${(props) => (props.secondary ? '27px' : props.bold ? '34px' : props.smallBold  ? '60px' : null)};
		padding-top: ${(props) => (props.smallBold  ? '10px' : null)};
		margin-bottom: ${(props) => (props.secondary ? '30px' : props.bold ? '50px' : null)};

	}

	@media (min-width: 1440px) {
		margin-bottom: ${(props) => (props.secondary ? '50px' : null)};

	}





	h2 {
		margin: 0;
		padding:0;
		font-size: ${(props) => (props.secondary ? '32px' : props.bold ? '36px' : props.smallBold  ? '20px' : null)};
		letter-spacing: -0.25px;
		font-family: ${(props) => (props.secondary ? 'Freight Big' : props.bold ? 'Maison Neue' : null)};
		line-height: 100%;
		text-transform: ${(props) => (props.secondary ? 'none' : props.bold ? 'uppercase' : null)};

		@media (min-width: 768px) {
			font-size: ${(props) => (props.secondary ? '48px' : props.bold ? '52px' : props.smallBold  ? '28px' : null)};
		}

		@media (min-width: 1024px) {
			font-size: ${(props) => (props.secondary ? '48px' : props.bold ? '72px' : null)};
		}

		::before {
			content: "";
			position: absolute;
			bottom: 0;
			margin: 0;
			background: black;
			height: 1px;
			width: 100%;
			display: block;
		}
	}

	a {
		font-size: 12px;
		font-weight: 500;
		text-decoration: none;
		color: #212c1f;


    :hover {
      color:#a55331;
    }
  

		@media (min-width: 768px) {
			font-size: 14px;
		}
	}
`;

export const CategoryTest = styled.div`

border: 1px solid teal;

svg {
	width:30px;
	margin:0 20px;
	path {
	/* color: red; */
		stroke:red;
		fill: none;
		/* color: red;
		fill: inherit;
		stroke: inherit; */
	}
}`;