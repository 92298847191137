import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { TwoGrid, StoriesWidget } from './style'

import { Teaser } from '../Teaser'
import {
  OverflowContainer,
  ComponentContainer,
} from '../../utils/globalStyledComponents.js'
import { SectionHeader } from '../SectionHeader'

const PostDetails = () => {
  const data = useStaticQuery(graphql`
    query {
      stories: allWpPost(
        limit: 2
        sort: { fields: date, order: DESC }
        filter: {
          categories: { nodes: { elemMatch: { slug: { ne: "industry-hub" } } } }
        }
      ) {
        nodes {
          title
          excerpt
          uri
          primaryCat {
            node {
              name
              slug
            }
          }
          categories {
            nodes {
              slug
              name
            }
          }

          avContent {
            featureImage {
              sourceUrl
              srcSet
            }
          }
        }
      }
    }
  `)

  return (
    <StoriesWidget>
      <OverflowContainer>
        <SectionHeader
          secondary
          title="Stories"
          more="VIEW ALL STORIES"
          path="/stories"
        />

        <TwoGrid>
          {data.stories.nodes.map((teaser, index) => (
            <Teaser
              img={teaser.avContent.featureImage}
              key={index}
              relatedTwo
              storyMain
              noLine
              categories={[teaser.primaryCat.node]}
              title={teaser.title}
              desc={teaser.excerpt}
              url={teaser.uri}
            />
          ))}
        </TwoGrid>
      </OverflowContainer>
    </StoriesWidget>
  )
}

export default PostDetails
