import React, { Component } from 'react'
import { Header } from './style.js'
import { Link } from 'gatsby'

class SectionHeader extends Component {
  render() {
    return (
      <div>
        <Header {...this.props}>
          <h2
            className="section-header"
            dangerouslySetInnerHTML={{ __html: this.props.title }}
          />

          {this.props.more && (
            <Link to={this.props.path}>{this.props.more}</Link>
          )}
        </Header>
      </div>
    )
  }
}

export default SectionHeader
