import React, { Component } from 'react';
import { StarContainer } from './style';
import { ReactComponent as Star } from '../../images/star.svg';

class Stars extends Component {
	constructor(props) {
		super(props);
		this.starContainerRef = React.createRef();
	}

	componentDidMount() {
		// console.log(this.props);
    // console.log(this.starContainerRef.current.children);
    
    const stars = this.starContainerRef.current.children

    let i;
    for (i = 0; i < this.props.stars; i++) {
      stars[i].classList.add('solid')
    }
	}
	render() {
		return (
			<StarContainer ref={this.starContainerRef}>
				<Star />
				<Star />
				<Star />
				<Star />
				<Star />
			</StarContainer>
		);
	}
}

export default Stars;
