import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: ${props => (props.standard ? '33.333% auto' : '1fr')};
  grid-column-gap: ${props => (props.standard ? '15px' : null)};
  padding-bottom: ${props =>
    props.articleMain ? '26px' : props.article ? '20px' : '0'};

  ::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: ${props =>
      props.article || props.articleMain ? 'block' : 'none'};
    height: 1px;
    background: #212c1f;
  }

  @media (min-width: 480px) {
    grid-template-columns: ${props => (props.standard ? '1fr' : null)};
    grid-template-rows: ${props => (props.standard ? 'auto 1fr' : null)};
  }

  .gatsby-image-wrapper {
    transition: transform 400ms ease-in-out;
  }
  :hover {
    .date {
      :after {
        opacity: 1;
      }
    }

    .gatsby-image-wrapper {
      transform: scale(1.05);
    }

    .overlay {
      opacity: 0.2;
      transition: 400ms;
    }

    .category {
      color: ${props => (props.article || props.articleMain) && '#a55331'};
    }
    img,
    svg {
      path {
        stroke: #a55331;
      }
    }
  }

  @media (min-width: 768px) {
    grid-template-columns: ${props =>
      props.article ? ' 1fr 1fr 1fr' : props.featured ? '1fr 1fr 1fr' : null};
    grid-column-gap: ${props =>
      props.featured ? '30px' : props.article ? '20px' : null};

    ::after {
      display: ${props => (props.articleMain ? 'none' : null)};
    }
  }

  @media (min-width: 1024px) {
    padding-bottom: ${props => (props.article ? '30px' : null)};
    grid-column-gap: ${props =>
      props.featured ? '55px' : props.article ? '40px' : null};
  }

  @media (min-width: 1280px) {
    padding-bottom: ${props =>
      props.articleMain ? '0' : props.article ? '40px' : null};
  }
`

export const ImgContainer = styled.div`
  overflow: hidden;
  margin-bottom: ${props =>
    props.accomodation
      ? ' 19px'
      : props.articleMain
      ? '25px'
      : props.featured
      ? '12px'
      : null};

  @media (min-width: 480px) {
    margin-bottom: ${props => (props.standard ? '20px' : null)};
  }

  @media (min-width: 768px) {
    grid-column: ${props => (props.featured ? 'span 2' : null)};
    margin-bottom: ${props =>
      props.article
        ? '0'
        : props.standard
        ? '16px'
        : props.featured
        ? '0'
        : null};
  }

  @media (min-width: 1024px) {
    margin-bottom: ${props =>
      props.accomodation
        ? '24px'
        : props.article
        ? '0'
        : props.standard
        ? '34px'
        : null};
  }
`

export const ImgWrapper = styled.div`
  display: block;
  overflow: hidden;
  padding-top: ${props =>
    props.accomodation
      ? '100%'
      : props.articleMain
      ? '62.8%'
      : props.article
      ? '76%'
      : props.featured
      ? '74.747%'
      : props.standard
      ? '75%'
      : '100%'};
  position: relative;
  min-height: ${props => (props.article ? null : '86px')};
  height: 100%;
  @media (min-width: 768px) {
    min-height: ${props => (props.featured ? '408px' : null)};
  }

  > div,
  img {
    position: absolute !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`

export const ImgMain = styled.img`
  position: absolute;

  top: 0;
  width: 100%;
  height: 100%;

  margin: 0;
`

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: ${props => (props.standard ? 'space-between' : null)};
	/* padding: ${props => (props.accomodation ? '0 40px' : null)}; */

	@media (min-width: 768px) {
		justify-content: ${props => (props.featured ? 'space-between' : null)};
		grid-column: ${props => (props.article ? 'span 2' : null)};

	}

	@media (min-width: 1280px) {
		padding-top: ${props => (props.featured ? '46px' : null)};
	}


`

export const TextContainer = styled.div`
display:flex;
flex-direction: column;
height: 100%;
justify-content: ${props => (props.article ? 'space-between' : null)};

	padding-bottom: ${props => (props.featured ? '27px' : null)};


	@media(min-width: 480px) {
		padding-bottom: ${props =>
      props.article ? '0' : props.standard ? '30px' : null};
	}

	@media(min-width: 768px) {
		/* height: ${props => (props.standard ? '260px' : null)}; */
		/* padding-bottom: ${props => (props.featured ? '30px' : null)}; */

		

	}

	@media(min-width: 1024px) {
		/* height: ${props => (props.standard ? '300px' : null)}; */
	}


	a {
		::after {
			position:absolute;
			content:'';
			width:100%;
			height:100%;
			top:0;
			left:0;
		}
	}

`

export const Title = styled.h3`
  text-transform: none;
  font-size: ${props =>
    props.accomodation
      ? '24px'
      : props.featured
      ? '28px'
      : props.standard
      ? '18px'
      : null};
  line-height: ${props => (props.featured ? '107.14%' : null)};
  margin-bottom: ${props =>
    props.accomodation
      ? '13px'
      : props.articleMain
      ? '16px'
      : props.featured
      ? '29px'
      : '0'};

  @media (min-width: 480px) {
    margin-bottom: ${props =>
      props.article ? '10px' : props.featured ? '29px' : '15px'};
  }

  @media (min-width: 768px) {
    font-size: ${props =>
      props.articleMain || props.featured
        ? '25px'
        : props.standard
        ? '23px'
        : null};
    margin-bottom: ${props => (props.featured ? '20px' : null)};
  }

  @media (min-width: 1024px) {
    line-height: ${props => (props.featured ? '100%' : null)};
    font-size: ${props =>
      props.article
        ? '24px'
        : props.standard
        ? '32px'
        : props.articleMain || props.featured
        ? '36px'
        : null};
  }

  @media (min-width: 1280px) {
    font-size: ${props =>
      props.accomodation || props.articleMain || props.featured
        ? '32px'
        : props.article
        ? '24px'
        : null};
    margin-bottom: ${props => (props.accomodation ? '24px' : null)};
  }
`

export const Excerpt = styled.p`
  display: ${props => (props.standard || props.article ? 'none' : 'block')};

  font-size: ${props => (props.articleMain || props.featured ? '16px' : null)};
  line-height: 150%;
  margin: 0;
  margin-bottom: ${props => (props.articleMain ? ' 16px' : null)};

  @media (min-width: 480px) {
    display: ${props =>
      props.article ? 'none' : props.standard ? 'block' : null};
  }

  @media (min-width: 768px) {
    font-size: ${props =>
      props.articleMain || props.featured
        ? '16px'
        : props.standard
        ? '16px'
        : null};
  }

  @media (min-width: 1280px) {
    font-size: ${props =>
      props.articleMain || props.featured
        ? '20px'
        : props.standard
        ? '18px'
        : null};
  }
`

export const Category = styled.div`
  order: ${props => (props.articleMain || props.article ? '2' : null)};

  display: ${props =>
    props.article ? 'block' : props.standard ? 'none' : 'block'};
  margin-bottom: ${props =>
    props.article || props.articleMain ? '0' : props.featured ? '19px' : '9px'};

  @media (min-width: 480px) {
    display: ${props => (props.standard ? 'flex' : null)};
  }

  img,
  svg {
    margin: 0;
    height: ${props => (props.featured ? '20px' : '20px')};
    width: ${props => (props.featured ? '20px' : '20px')};
    margin-right: ${props => (props.featured ? '10px' : '10px')};
    path {
      stroke: #545c52;
    }
  }

  span {
    font-size: ${props => (props.featured ? '12px' : '12px')};
    font-weight: 500;
    line-height: 166.666%;

    @media (min-width: 1024px) {
      font-size: ${props => (props.standard ? '14px' : '14px')};
    }
  }
`

export const Date = styled.p`
  display: ${props =>
    props.accomodation || props.articleMain || props.article
      ? 'none !important'
      : null};
  margin: 0;
  font-size: ${props => (props.featured ? '12px' : '12px')};
  /* line-height: 166.6667%; */
  padding-top: ${props =>
    !props.date ? '2px' : props.featured ? '6px' : '6px'};
  position: relative;

  @media (min-width: 480px) {
    padding-top: ${props =>
      props.featured || !props.date || props.standard ? '14px' : null};
    min-height: 30px;
  }

  @media (min-width: 768px) {
    justify-self: ${props => (props.featured ? 'end' : null)};
  }

  @media (min-width: 1024px) {
    font-size: ${props => (props.standard ? '14px' : '14px')};
    min-height: 34px;

    padding-top: ${props => (props.featured || props.standard ? '15px' : null)};
  }

  :before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    top: 0;
    left: 0;
    background: black;
    bottom: top;
    opacity: 0.4;
  }

  ::after {
    display: block;
    content: '';
    position: absolute;
    height: 4px;
    width: 100%;
    z-index: 2;
    transform: translateY(-2px);
    top: 0;
    transition: 160ms;
    opacity: 0;
    background-color: #a55331;
  }
`

export const Featured = styled.h5`
  font-family: Freight Big;
  font-size: 18px;
  text-transform: none;
  line-height: 233.333333%;
  margin: 0;

  @media (min-width: 768px) {
    font-size: 20px;
  }
  @media (min-width: 1024px) {
    font-size: 24px;
    margin-bottom: 6px;
  }
`

export const StarContainer = styled.div`
  svg {
    margin-right: 5px;
  }

  svg.solid {
    path {
      stroke: #212c1f;
      fill: #212c1f;
    }
  }
`

export const ImageOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: 80ms;
  background: black;
  z-index: 0;
  opacity: 0;
`
