import React from 'react'
import Stars from './stars'
import Makers from '../../images/makers-icon.svg'
import { getImageRatio } from '../../utils/helpers'
import FancyLink from '../../utils/FancyLink.js'
import { GetCatIcon, hasIcon } from '../../utils/NewCategoryicons'
import { dateLabel } from '../../utils/dateLabel'

import {
  Container,
  ImgContainer,
  Category,
  ImgMain,
  ImgWrapper,
  TextContainer,
  Title,
  Featured,
  Excerpt,
  Date,
  InfoContainer,
  ImageOverlay,
} from './style.js'

import { ResponsiveImgMain } from '../Teaser/style.js'

const TeaserUnearth = props => {
  return (
    <Container {...props}>
      <ImgContainer {...props}>
        <ImgWrapper {...props}>
          {props.fluid ? (
            <ResponsiveImgMain
              aspectRatio={getImageRatio(props.fluid.srcSet)}
              className="teaser_img"
              fluid={props.fluid}
              alt=""
            />
          ) : (
            <img
              src="https://res.cloudinary.com/avenue/image/upload/v1637111400/vb-placeholder_kwrhkq.png"
              alt="teaserimg"
            />
          )}

          <ImageOverlay className="overlay" />
        </ImgWrapper>
      </ImgContainer>
      <InfoContainer {...props} className="info">
        <TextContainer {...props}>
          {props.featured && <Featured {...props}>Featured</Featured>}
          <FancyLink
            to={props.path ? props.path.replace(/^.*\/\/[^\/]+/, '') : '/'}
          >
            <Title
              {...props}
              dangerouslySetInnerHTML={{
                __html: props.title,
              }}
            />
          </FancyLink>
          {props.category && props.category.length > 0 && (
            <Category {...props}>
              {hasIcon(props.category[0]) && (
                <GetCatIcon category={props.category[0]} />
              )}
              <span
                className="category"
                dangerouslySetInnerHTML={{
                  __html: props.category[0],
                }}
              />
            </Category>
          )}

          <Excerpt
            {...props}
            dangerouslySetInnerHTML={{
              __html: props.excerpt,
            }}
          />
        </TextContainer>

        {props.stars && <Stars stars={props.stars} />}

        <Date className="date" {...props}>
          {props.date && dateLabel(props.date)}
        </Date>
      </InfoContainer>
    </Container>
  )
}

export default TeaserUnearth
