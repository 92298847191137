import React from 'react';
import { Header, TwoGrid, ThreeGrid, MoreItineraries, RelatedArticlesContainer } from './style.js';
import { Teaser } from '../Teaser';
import PropTypes from 'prop-types';
import { StoriesWidget } from '../StoriesWidget';
import { Link } from 'gatsby';

import { OverflowContainer, ComponentContainer } from '../../utils/globalStyledComponents.js';

const RelatedArticles = ({ heading, relatedPages, itineraries, noBorder, unearth }) => {
	if (!itineraries && !relatedPages) {
		return <StoriesWidget />;
	}

	return (
		<RelatedArticlesContainer>
			{/* <ComponentContainer>
				<OverflowContainer> */}
					<Header unearth={unearth} noBorder={noBorder}>{heading ? heading : 'Related Articles'}</Header>

					{itineraries ? (
						<MoreItineraries>
							<ThreeGrid>
								{relatedPages.map((teaser, index) => (
									<Teaser
										key={index}
										itinerary
										days={teaser.avItinerary.summary.days}
										img={{
											...teaser.avContent.featureImage,
											sizes: '520px'
										}}
										title={teaser.title}
										url={teaser.uri}
									/>
								))}
							</ThreeGrid>

							<h5>
								<Link to="/things-to-do/itineraries/">VIEW ALL ITINERARIES</Link>
							</h5>
						</MoreItineraries>
					) : (
						<TwoGrid>
							{relatedPages ? (
								relatedPages.map((teaser, index) => (
									<Teaser

										img={{
											...teaser.avContent?.featureImage,
											sizes: '574px'
										}}
										key={index}
										relatedTwo
										storyMain
										noLine
										categories={teaser.categories?.nodes}
										foodCategory="Food & Drink"
										title={teaser.title}
										desc={teaser.excerpt}
										url={teaser.uri}
									/>
								))
							) : null}
						</TwoGrid>
					)}
				{/* </OverflowContainer>
			</ComponentContainer> */}
		</RelatedArticlesContainer>
	);
};

export default RelatedArticles;

RelatedArticles.propTypes = {
	noBorder: PropTypes.bool
};
